import React, { useState, useEffect } from 'react';
import * as style from './style.module.sass';

const Conversion = ({ conversion_ids }) => {
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [html, setHtml] = useState(null);
  const index = conversion_ids.indexOf(currentId);

  useEffect(() => {
    window.openDetail = setCurrentId;
  }, []);

  useEffect(() => {
    if (!currentId) return;
    setLoading(true);
    fetch(`/conversions/${currentId}?raw=1`)
      .then((data) => data.text())
      .then((res) => {
        setHtml(res);
        setLoading(false);
      });
  }, [currentId]);

  if (!currentId) return null;

  return (
    <div className={style.conversion} onClick={() => setCurrentId(null)}>
      <div className={style.conversion__inner} onClick={(e) => e.stopPropagation()}>
        <div className={style.conversion__arrows}>
          <span
            className={style.conversion__arrow}
            onClick={() => setCurrentId(conversion_ids[index - 1])}
            data-disabled={index === 0}
          >
            ▲
          </span>
          <span
            className={style.conversion__arrow}
            onClick={() => setCurrentId(conversion_ids[index + 1])}
            data-disabled={index === conversion_ids.length - 1}
          >
            ▼
          </span>
        </div>
        {loading ? <p>loading...</p> : <div dangerouslySetInnerHTML={{ __html: html }} />}
      </div>
    </div>
  );
};

export default Conversion;

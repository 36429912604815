import React from 'react';
import { createRoot } from 'react-dom/client';

const mount = (components) => {
  document.addEventListener('turbo:load', () => {
    Object.entries(components).forEach(([react_class, Component]) => {
      const nodes = document.querySelectorAll(`[data-react-class="${react_class}"]`);
      if (!nodes) return;
      nodes.forEach((node) => {
        const propsJSON = node.getAttribute('data-react-props') || '{}';
        const props = JSON.parse(propsJSON);
        const root = createRoot(node);
        root.render(<Component {...props} />);
      });
    });
  });
};

export default mount;

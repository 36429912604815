import React, { useState, useEffect } from 'react';
import * as style from './style.module.sass';

const Modal = ({ flash: [flash] }) => {
  useEffect(() => {
    return () => unmount();
  }, []);

  if (!flash) return null;
  const [type, message] = flash;
  if (!message) return null;

  const [open, setOpen] = useState(true);
  if (!open) return null;

  return (
    <div className={style.modal} onClick={() => setOpen(false)}>
      <div className={style.modal__wrap} onClick={(e) => e.stopPropagation()}>
        <div className={style.modal__content}>{message}</div>
        <nav onClick={() => setOpen(false)} className={style.modal__close}>
          閉じる
        </nav>
        <div onClick={() => setOpen(false)} className={style.modal__icon}>
          ×
        </div>
      </div>
    </div>
  );
};

export default Modal;

import '@hotwired/turbo-rails';
import mount from 'helpers/mount';
import register from 'helpers/register';

import Conversion from 'components/conversion';
import Modal from 'components/modal';

mount({ Conversion, Modal });

import copyToClipboard from 'utils/copy_to_clipboard';

register({ copyToClipboard });
